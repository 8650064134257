import React, { useState } from "react";
import {faSchool} from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";
import Modal from "../common/modal"; // Import the modal component

import "./styles/works.css";

const Education = () => {
    const [selectedWork, setSelectedWork] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const workExperiences = [
        {
            id: 1,
            image: "https://ires.ubc.ca/files/2020/05/national-university-of-singapore-nus-logo-singapore.jpg",
            title: "National University of Singapore",
            subtitle: "Bachelor of Computing",
            duration: "Present",
            details: ["BComp, BBA", "University Scholars Programme, Case Consulting Group", "Stephen Riady Young Entrepreneur Scholarship", "NUSC Honour Roll, SOC Certificate of Recognition"]
        },
        {
            id: 2,
            image: "https://identity.stanford.edu/wp-content/uploads/sites/3/2020/07/block-s-right.png",
            title: "Stanford University",
            subtitle: "Entrepreneurial Studies",
            duration: "Apr 2023 - Aug 2023",
            details: ["MS&E178: The Spirit of Entrepreneurship", "MS&E472: Entrepreneurial Thought Leaders Seminar"]
        },
    ];

    const handleWorkClick = (work) => {
        setSelectedWork(work);
        setIsModalOpen(true);
    };

    return (
        <div className="works">
            <Card
                icon={faSchool}
                title="Education"
                body={
                    <div className="works-body">
                        {workExperiences.map(work => (
                            <div key={work.id} className="work" onClick={() => handleWorkClick(work)}>
                                <img
                                    src={work.image}
                                    alt={work.title}
                                    className="work-image"
                                />
                                <div className="work-title">{work.title}</div>
                                <div className="work-subtitle">{work.subtitle}</div>
                                <div className="work-duration">{work.duration}</div>
                            </div>
                        ))}
                    </div>
                }
            />
            {isModalOpen && selectedWork && (
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <img
                                src={selectedWork.image}
                                alt={selectedWork.title}
                                className="modal-work-image"
                            />
                            <div className="modal-header-text">
                                <div className="modal-work-title">{selectedWork.title}</div>
                                <div className="modal-work-subtitle">{selectedWork.subtitle}</div>
                            </div>
                        </div>
                        <p>
                            <ul>
                              {selectedWork.details.map((detail, index) => (
                                <li key={index}>{detail}</li>
                              ))}
                            </ul>
                        </p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Education;
