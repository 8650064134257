import React, { useState } from 'react';
import Project from './project';
import INFO from '../../data/user';
import Pagination from './pagination'; // You will need to create this component
import './styles/allProjects.css';

const AllProjects = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 6; // Adjust this as needed

    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = INFO.projects.slice(indexOfFirstProject, indexOfLastProject);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <div className="all-projects-container">
                {currentProjects.map((project, index) => (
                    <div className="all-projects-project" key={index}>
                        <Project
                            logos={project.logos}
                            title={project.title}
                            description={project.description}
                            linkText={project.linkText}
                            link={project.link}
                        />
                    </div>
                ))}
            </div>
            <Pagination
                projectsPerPage={projectsPerPage}
                totalProjects={INFO.projects.length}
                paginate={paginate}
            />
        </div>
    );
};

export default AllProjects;
