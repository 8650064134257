import './styles/pagination.css';

const Pagination = ({ projectsPerPage, totalProjects, paginate }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalProjects / projectsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="pagination">
            {pageNumbers.map(number => (
                <button key={number} onClick={() => paginate(number)}>
                    {number}
                </button>
            ))}
        </div>
    );
};

export default Pagination;
