import React, { useState } from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";
import Modal from "../common/modal"; // Import the modal component

import "./styles/works.css";

const Works = () => {
    const [selectedWork, setSelectedWork] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const workExperiences = [
        {
            id: 1,
            image: "./unit21.png",
            title: "Unit21",
            subtitle: "Software Engineer Intern (Data)",
            duration: "Jan 2023 - Present",
            details: "Unit testing"
        },
        {
            id: 2,
            image: "./mlabs.png",
            title: "Marymount Labs",
            subtitle: "Founder",
            duration: "Nov 2022 - Present",
            details: "COVID testing"
        },
        {
            id: 3,
            image: "./flash.png",
            title: "Flash Coffee",
            subtitle: "Venture Development Intern",
            duration: "May 2022 - Aug 2022",
            details: "Taste testing"
        },
                {
            id: 4,
            image: "./500.png",
            title: "500 Global",
            subtitle: "Venture Builder",
            duration: "May 2021 - Aug 2021",
            details: "A/B testing"
        },
    ];

    const handleWorkClick = (work) => {
        setSelectedWork(work);
        setIsModalOpen(true);
    };

    return (
        <div className="works">
            <Card
                icon={faBriefcase}
                title="Work"
                body={
                    <div className="works-body">
                        {workExperiences.map(work => (
                            <div key={work.id} className="work" onClick={() => handleWorkClick(work)}>
                                <img
                                    src={work.image}
                                    alt={work.title}
                                    className="work-image"
                                />
                                <div className="work-title">{work.title}</div>
                                <div className="work-subtitle">{work.subtitle}</div>
                                <div className="work-duration">{work.duration}</div>
                            </div>
                        ))}
                    </div>
                }
            />
            {isModalOpen && selectedWork && (
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <img
                                src={selectedWork.image}
                                alt={selectedWork.title}
                                className="modal-work-image"
                            />
                            <div className="modal-header-text">
                                <div className="modal-work-title">{selectedWork.title}</div>
                                <div className="modal-work-subtitle">{selectedWork.subtitle}</div>
                            </div>
                        </div>
                        <p>{selectedWork.details}</p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default Works;
