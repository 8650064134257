const INFO = {
	main: {
		title: "Zacchaeus",
		name: "Zacchaeus Chok",
		email: "zacchaeus.chok@u.nus.edu",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/zacchaeuschok",
		linkedin: "https://linkedin.com/in/zacchaeuschok",
	},

	homepage: {
		title: "Hi, I'm Zacchaeus.",
		description:
			"I'm a junior at the National University of Singapore with a focus on Artificial Intelligence and Database Systems. My experiences include founding Marymount Labs to develop digital tools for healthcare and working on big data infrastructure at a Series C Fintech.",
	},

	about: {
		title: "I’m Tharindu N. I live in Sri Lanka, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "Blog",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "InterLang",
			description:
				"Integrating health insights through Java-based LLM chatbot and FHIR server.",
			logos: ["https://cdn.worldvectorlogo.com/logos/java.svg", "https://cdn.analyticsvidhya.com/wp-content/uploads/2023/07/langchain3.png", "intersystems.png"],
			linkText: "View Project",
			link: "https://openexchange.intersystems.com/package/InterLang",
		},
		{
			title: "PodcastGPT",
			description:
				"Crafting personalized podcasts with voice synthesis and LLM agents.",
			logos: ["https://static-00.iconduck.com/assets.00/openai-icon-2021x2048-4rpe5x7n.png", "https://media.trustradius.com/vendor-logos/Tn/j4/3PZ86IEWBBX9-180x180.JPEG"],
			linkText: "View Project",
			link: "https://devpost.com/software/podcastgpt",
		},
		{
			title: "MBTIFY",
			description:
				"Transforming personality assessments with LLM chatbots.",
			logos: ["https://static-00.iconduck.com/assets.00/openai-icon-2021x2048-4rpe5x7n.png", "intersystems.png"],
			linkText: "View Project",
			link: "https://devpost.com/software/mbtify",
		},

		{
			title: "Raja",
			description:
				"\n" +
				"Boosting developer productivity by autonomously generating pull requests.",
			logos: ["https://static-00.iconduck.com/assets.00/openai-icon-2021x2048-4rpe5x7n.png", "https://seeklogo.com/images/P/pinecone-icon-logo-AF8B5B7F96-seeklogo.com.png", "https://cdn.analyticsvidhya.com/wp-content/uploads/2023/07/langchain3.png"],
			linkText: "View Project",
			link: "https://devpost.com/software/raja-ai",
		},

		{
			title: "GENESIS",
			description:
				"Accelerating ML with synthetic data generation for primary care clinics.",
			logos: ["https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Huawei_Standard_logo.svg/800px-Huawei_Standard_logo.svg.png"],
			linkText: "View Project",
			link: "https://www.comp.nus.edu.sg/news/2022-zacchaeus/",
		},

		{
			title: "Infodemic",
			description:
				"Modelling anti-vaxxer sentiment as a culturally transmitted trait.",
			logos: ["https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png"],
			linkText: "View Project",
			link: "https://github.com/zacchaeuschok/indodemic-epidemic",
		},

		{
			title: "ResumeWork",
			description:
				"Empowering job-seekers with NLP-driven resume recommendations.",
			logos: ["https://huggingface.co/datasets/huggingface/brand-assets/resolve/main/hf-logo.png"],
			linkText: "View Project",
			link: "https://github.com",
		},
	],
};

export default INFO;
