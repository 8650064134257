const SEO = [
	{
		page: "home",
		description:
			"I'm a junior at the National University of Singapore with a focus on Artificial Intelligence and Database Systems. My experiences include founding Marymount Labs to develop digital tools for healthcare and working on big data infrastructure at a Series C Fintech.",
		keywords: ["Zacchaeus", "Zacchaeus C", "Zacchaeus Chok"],
	},

	{
		page: "about",
		description:
			"I'm a junior at the National University of Singapore with a focus on Artificial Intelligence and Database Systems. My experiences include founding Marymount Labs to develop digital tools for healthcare and working on big data infrastructure at a Series C Fintech.",
		keywords: ["Zacchaeus", "Zacchaeus C", "Zacchaeus Chok"],
	},

	{
		page: "articles",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
		keywords: ["Zacchaeus", "Zacchaeus C", "Zacchaeus Chok"],
	},

	{
		page: "projects",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made.",
		keywords: ["Zacchaeus", "Zacchaeus C", "Zacchaeus Chok"],
	},

	{
		page: "contact",
		description:
			"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
		keywords: ["Zacchaeus", "Zacchaeus C", "Zacchaeus Chok"],
	},
];

export default SEO;
